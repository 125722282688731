<template>
  <b-navbar
    :class="classList"
    toggleable="lg"
    type="dark"
  >
    <b-container>
      <div>
        <b-navbar-brand
          class="text-decoration-none"
          to="/"
        >
          develop<span class="text-orange">4</span><span class="text-gray-light">edu</span>
        </b-navbar-brand>

        <div class="mr-3 mt-n1 small navbar-brand-subtitle text-gray-light">
          Ein Unternehmen der
          <b-link
            class="text-decoration-none text-gray-light"
            href="//www.klett-gruppe.de"
            target="_blank"
          >
            <strong>Klett Gruppe</strong>
          </b-link>
        </div>
      </div>

      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <font-awesome-icon
            v-if="expanded"
            :icon="['fas', 'times']"
          />
          <font-awesome-icon
            v-else
            :icon="['fas', 'bars']"
          />
        </template>
      </b-navbar-toggle>

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav class="ml-auto">
          <b-navbar-nav>
            <b-nav-item to="/#philosophy">
              Philosophie
            </b-nav-item>
            <b-nav-item to="/#technology">
              Technologie
            </b-nav-item>
            <b-nav-item to="/#jobs">
              Jobs
            </b-nav-item>
            <b-nav-item to="/#contact">
              Kontakt
            </b-nav-item>
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      classList: {
        'bg-gray-dark': true,
        'fixed-top': true,
        isShrinked: false,
      },
    };
  },
  mounted() {
    window.onscroll = () => {
      this.onScroll();
    };
  },
  methods: {
    onScroll() {
      this.classList.isShrinked = document.documentElement.scrollTop > 189;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

.navbar {
  background-color: $gray-800;
  padding-bottom: .5rem;
  padding-top: .5rem;

  &.isShrinked {
    .navbar-brand {
      font-size: 2rem;
    }

    .navbar-brand-subtitle {
      display: none;
    }
  }

  a {
    &:focus,
    &:hover {
      color: $white !important;
    }
  }
}

.navbar-brand {
  font-family: 'Isonorm W01 Regular';
  font-size: 3rem;
  padding-bottom: 0;
  padding-top: 0;
  transition: font-size .15s;
}

.navbar-toggler {
  font-size: 1.5rem;

  &:focus {
    outline: none;
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    &.isShrinked {
      font-size: 1rem;
      padding-bottom: 0;
      padding-top: 0;

      .navbar-brand {
        font-size: 2rem;
      }
    }
  }

  .navbar-brand {
    font-size: 4rem;
  }
}
</style>
