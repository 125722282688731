import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/datenschutzerklaerung',
    component: () => import('../views/Datenschutzerklaerung.vue'),
  },
  {
    path: '/impressum',
    component: () => import('../views/Impressum.vue'),
  },
];

const router = new VueRouter({
  // Works with and without -> mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' });
    }
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
});

export default router;
