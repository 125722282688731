<template>
  <div class="bg-white d-flex flex-column h-100">
    <Navbar />

    <router-view
      id="app"
      class="flex-shrink-0"
    />

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Footer,
    Navbar,
  },
};
</script>

<style lang="scss">
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

body {
  padding-top: 6.75rem !important;
}

.modal-content {
  color: $body-color !important;
}

button:focus {
  box-shadow: none;
  outline: none;
}

section {
  padding-bottom: 3.5rem;
  padding-top: 3.5rem;
}

@include media-breakpoint-up(md) {
  body {
    padding-top: 8.25rem !important;
  }
}
</style>
