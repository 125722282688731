import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import VueParticles from 'vue-particles';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faExternalLinkAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faBootstrap, faCss3Alt, faHtml5, faSymfony, faUbuntu, faVuejs } from '@fortawesome/free-brands-svg-icons';

import App from './App.vue';
import router from './router';

import 'source-sans-pro/source-sans-pro.css';
// eslint-disable-next-line
import './scss/main.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(VueParticles);

Vue.component('font-awesome-icon', FontAwesomeIcon);
dom.watch();
library.add(
  faHtml5,
  faCss3Alt,
  faBootstrap,
  faSymfony,
  faVuejs,
  faUbuntu,
  faBars,
  faTimes,
  faExternalLinkAlt,
);

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
