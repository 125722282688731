<template>
  <div>
    <Banner />
    <Pillars />
    <Philosophy />
    <Technology />
    <Jobs />
    <Contact />
    <Goatie />
  </div>
</template>

<script>
import Banner from '../components/Home/Banner.vue';
import Contact from '../components/Home/Contact.vue';
import Goatie from '../components/Home/Goatie.vue';
import Jobs from '../components/Home/Jobs.vue';
import Philosophy from '../components/Home/Philosophy.vue';
import Pillars from '../components/Home/Pillars.vue';
import Technology from '../components/Home/Technology.vue';

export default {
  name: 'Home',
  components: {
    Philosophy,
    Technology,
    Pillars,
    Banner,
    Contact,
    Goatie,
    Jobs,
  },
};
</script>
